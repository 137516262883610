import React from 'react'
import SEO from '../components/seo'
import Header from '../containers/header'
import Footer from '../containers/footer'
import ErrorArea from '../containers/error-area'


const NotFoundPage = () => {
  // https://github.com/gatsbyjs/gatsby/issues/5329
  const browser = typeof window !== "undefined" && window;
  return (browser && (
    <>
      <SEO
        title="404: Pagina niet gevonden"
        description="404: Pagina niet gevonden"
        url="https://www.west-brabant.eu/404"
      />
      <Header transparent />
      <ErrorArea />
      <Footer />
    </>
  ))
}

export default NotFoundPage
