import React from 'react'
import { Container, Row, Col } from '../../components/ui/wrapper'
import Button from '../../components/ui/button'
import { ErrorWrap } from './error-area.style'

const ErroArea = () => {
  return (
    <ErrorWrap role="alert">
      <Container>
        <Row>
          <Col xl={7} lg={8} ml="auto" mr="auto">
            <h1>404</h1>
            <h2>OOPS! WE KUNNEN DE PAGINA NIET VINDEN</h2>
            <p>
              Sorry de pagina die u zoekt bestaat niet, is verwijderd, heeft een
              andere naam of is tijdelijk niet beschikbaar.
            </p>
            <Button to="/" hover="false">
              Terug naar de home pagina
            </Button>
          </Col>
        </Row>
      </Container>
    </ErrorWrap>
  )
}

export default ErroArea
